<template>
  <div>
    <el-dialog
    class="dialog"
      v-model="dialogVisible"
      :title="this.paramCheck.comName"
      width="90%"
      :before-close="handleClose"
      destroy-on-close
    >
      <!-- 小规模 -->
      <smallVatVue :paramCheck="paramCheck" v-if="paramCheck.tableName == 'gs_small_vat'"></smallVatVue>
      <!-- 一般纳税人 -->
      <vatInvoice :paramCheck="paramCheck" v-if="paramCheck.tableName == 'gs_vat'"></vatInvoice>
      <!-- 财产和行为纳税 -->
      <propertyTax :paramCheck="paramCheck" v-if="paramCheck.tableName == 'gs_deed'"></propertyTax>
      <!-- 企业所得税 -->
      <qysds :paramCheck="paramCheck" v-if="paramCheck.tableName == 'gs_tax_quarter'"></qysds>
      <!-- 文化事业建设费 -->
      <culturalTax :paramCheck="paramCheck" v-if="paramCheck.tableName == 'gs_whsyjsfsbbygz'"></culturalTax>
      <!-- 城市生活垃圾处置费 -->
      <taxCityLife :paramCheck="paramCheck" v-if="paramCheck.tableName == 'gs_ljcl'"></taxCityLife>
      <!-- 消费税 -->
      <xfs :paramCheck="paramCheck" v-if="paramCheck.tableName == 'gs_xfs'"></xfs>
      <!-- 残保金 -->
      <cbj :paramCheck="paramCheck" v-if="paramCheck.tableName == 'gs_cbj'"></cbj>
      <!-- 财务报表 -->
      <finance :paramCheck="paramCheck" v-if="paramCheck.tableName == 'gs_cwbb'"></finance>

      <!-- 水利建设专项收入 -->
      <taxNeimengSl :paramCheck="paramCheck" v-if="paramCheck.tableName == 'gs_sl'  || paramCheck.tableName == 'gs_fssr'"></taxNeimengSl>
      <!-- 其他收入（工会经费） -->
      <taxNeimengQtsr :paramCheck="paramCheck" v-if="paramCheck.tableName == 'gs_qtsr' || paramCheck.tableName == 'gs_ghjf'"></taxNeimengQtsr>
      <!-- 附加税 -->
      <additionalTax :paramCheck="paramCheck" v-if="paramCheck.tableName == 'gs_fjs'"></additionalTax>
      <!-- 印花税纳税申报表 -->
      <editTax :paramCheck="paramCheck" v-if="paramCheck.tableName == 'gs_stamp_duty_item' || paramCheck.tableName == 'gs_stamp_duty_item_cb' || paramCheck.tableName == 'gs_stamp_duty_item_jb'"></editTax>

      <!-- 房产税土地税 -->
      <fcs :paramCheck="paramCheck" v-if="paramCheck.tableName == 'gs_fcs'"></fcs>

      <!-- 社保检查 -->
      <checkCjSheBao :paramCheck="paramCheck" v-if="paramCheck.tableName == 'tax-cj-shebao'"></checkCjSheBao>
      <!-- 定期定额 -->
      <dqde :paramCheck="paramCheck" v-if="paramCheck.tableName == 'gs_tax_dqde'"></dqde>

      <!-- 综合所得个人所得税预扣预缴申报表  -->
      <normal :paramCheck="paramCheck" v-if="paramCheck.tableName == 'gs_gs'"></normal>
    </el-dialog>
  </div>
</template>

<script>
import smallVatVue from '../../report/tax/small-vat/smallVat.vue';
import vatInvoice from '../../report/tax/vat-invoice/vatInvoice.vue';
import propertyTax from "../../report/tax/propertyTax/propertyTax.vue"
import qysds from "../../report/tax/qysds/qysds.vue"
import culturalTax from "../../report/tax/otherTable/culturalTax.vue"
import taxCityLife from "../../report/tax/otherTable/taxCityLife.vue"
import xfs from "../../report/tax/otherTable/xfs.vue"
import cbj from "../../report/tax/otherTable/cbj.vue"
import taxNeimengSl from '../../report/tax/otherTable/taxNeimengSl.vue';
import taxNeimengQtsr from '../../report/tax/otherTable/taxNeimengQtsr.vue';
import finance from '../../report/finance/finance.vue';
import dqde from '../../report/tax/otherTable/dqde.vue'
import checkCjSheBao from './checkCjSheBao';
import normal from '../../report/tax/otherTable/normal.vue';
export default {
  name: 'checkTbaleDialog',
  components:{
    smallVatVue,
    vatInvoice,
    propertyTax,
    qysds,
    culturalTax,
    taxCityLife,
    xfs,
    cbj,
    taxNeimengSl,
    taxNeimengQtsr,
    finance,
    checkCjSheBao,
    dqde,
    normal
  },
  data() {
    return {
      dialogVisible:false,
      paramCheck:{}
    };
  },

  mounted() {
    
  },

  methods: {
    openDialog(row){
      this.paramCheck = row
      //console.log(this.paramCheck);
      this.dialogVisible = true
    }
  },
};
</script>

<style lang="scss">
.dialog .el-dialog__body{
  padding-top: 0px;
}
</style>
